import { useEffect, useState, useRef } from "react";
import { isDesktop } from "react-device-detect";
import gsap from "gsap";

export default function OnlyHTML(props) {
  const [currentSelection, setCurrentSelection] = useState("S");
  //AUDIO
  var GenesisHover = useRef(new Audio("Assets/Sounds/GenesisHover.mp3"));
  var HarmonyHover = useRef(new Audio("Assets/Sounds/HarmonyHover.mp3"));
  var HorizonHover = useRef(new Audio("Assets/Sounds/HorizonHover.mp3"));
  var InsightHover = useRef(new Audio("Assets/Sounds/InsightHover.mp3"));
  var SpaceHover = useRef(new Audio("Assets/Sounds/SpaceHover.mp3"));
  var ThresholdHover = useRef(new Audio("Assets/Sounds/ThresholdHover.mp3"));
  var GenesisClick = useRef(new Audio("Assets/Sounds/GenesisClick.mp3"));
  var HarmonyClick = useRef(new Audio("Assets/Sounds/HarmonyClick.mp3"));
  var HorizonClick = useRef(new Audio("Assets/Sounds/HorizonClick.mp3"));
  var InsightClick = useRef(new Audio("Assets/Sounds/InsightClick.mp3"));
  var SpaceClick = useRef(new Audio("Assets/Sounds/SpaceClick.mp3"));
  var ThresholdClick = useRef(new Audio("Assets/Sounds/ThresholdClick.mp3"));

  GenesisHover.crossOrigin = "anonymous";
  HarmonyHover.crossOrigin = "anonymous";
  HorizonHover.crossOrigin = "anonymous";
  InsightHover.crossOrigin = "anonymous";
  SpaceHover.crossOrigin = "anonymous";
  ThresholdHover.crossOrigin = "anonymous";
  GenesisClick.crossOrigin = "anonymous";
  HarmonyClick.crossOrigin = "anonymous";
  HorizonClick.crossOrigin = "anonymous";
  InsightClick.crossOrigin = "anonymous";
  SpaceClick.crossOrigin = "anonymous";
  ThresholdClick.crossOrigin = "anonymous";

  useEffect(() => {
    const sides = document.querySelectorAll(".cube-visible");
    let title_a = document.querySelector(".nav-title-a");
    let title_b = document.querySelector(".nav-title-b");
    let scroll_back = document.querySelector(".scroll-back");

    sides.forEach((side) => {
      side.addEventListener("mouseenter", function handleTitleChange(event) {
        switch (event.target.id) {
          case "button-S":
            gsap.to(title_a, { opacity: 0, duration: 0.5 });
            gsap.to(title_b, { opacity: 0, duration: 0.5 }, ">");
            gsap.to(
              title_a,
              { opacity: 1, duration: 0.5, innerHTML: "IN" },
              "<"
            );
            gsap.to(
              title_b,
              { opacity: 1, duration: 0.5, innerHTML: "TRO" },
              ">"
            );

            break;
          case "button-A":
            gsap.to(title_a, { opacity: 0, duration: 0.5 });
            gsap.to(title_b, { opacity: 0, duration: 0.5 }, ">");
            gsap.to(
              title_a,
              { opacity: 1, duration: 0.5, innerHTML: "THRES" },
              "<"
            );
            gsap.to(
              title_b,
              { opacity: 1, duration: 0.5, innerHTML: "HOLD" },
              ">"
            );
            if (isDesktop) {
              ThresholdHover.current.play();
            }
            break;
          case "button-B":
            gsap.to(title_a, { opacity: 0, duration: 0.5 });
            gsap.to(title_b, { opacity: 0, duration: 0.5 }, ">");
            gsap.to(
              title_a,
              { opacity: 1, duration: 0.5, innerHTML: "GENE" },
              "<"
            );
            gsap.to(
              title_b,
              { opacity: 1, duration: 0.5, innerHTML: "SIS" },
              ">"
            );
            if (isDesktop) {
              GenesisHover.current.play();
            }
            break;
          case "button-C":
            gsap.to(title_a, { opacity: 0, duration: 0.5 });
            gsap.to(title_b, { opacity: 0, duration: 0.5 }, ">");
            gsap.to(
              title_a,
              { opacity: 1, duration: 0.5, innerHTML: "HORI" },
              "<"
            );
            gsap.to(
              title_b,
              { opacity: 1, duration: 0.5, innerHTML: "ZON" },
              ">"
            );
            if (isDesktop) {
              HorizonHover.current.play();
            }
            break;
          case "button-D":
            gsap.to(title_a, { opacity: 0, duration: 0.5 });
            gsap.to(title_b, { opacity: 0, duration: 0.5 }, ">");
            gsap.to(
              title_a,
              { opacity: 1, duration: 0.5, innerHTML: "SPA" },
              "<"
            );
            gsap.to(
              title_b,
              { opacity: 1, duration: 0.5, innerHTML: "CE" },
              ">"
            );
            if (isDesktop) {
              SpaceHover.current.play();
            }
            break;
          case "button-E":
            gsap.to(title_a, { opacity: 0, duration: 0.5 });
            gsap.to(title_b, { opacity: 0, duration: 0.5 }, ">");
            gsap.to(
              title_a,
              { opacity: 1, duration: 0.5, innerHTML: "IN" },
              "<"
            );
            gsap.to(
              title_b,
              { opacity: 1, duration: 0.5, innerHTML: "SIGHT" },
              ">"
            );
            if (isDesktop) {
              InsightHover.current.play();
            }
            break;
          case "button-F":
            gsap.to(title_a, { opacity: 0, duration: 0.5 });
            gsap.to(title_b, { opacity: 0, duration: 0.5 }, ">");
            gsap.to(
              title_a,
              { opacity: 1, duration: 0.5, innerHTML: "HAR" },
              "<"
            );
            gsap.to(
              title_b,
              { opacity: 1, duration: 0.5, innerHTML: "MONY" },
              ">"
            );
            if (isDesktop) {
              HarmonyHover.current.play();
            }
            break;
          case "button-G":
            gsap.to(title_a, { opacity: 0, duration: 0.5 });
            gsap.to(title_b, { opacity: 0, duration: 0.5 }, ">");
            gsap.to(
              title_a,
              { opacity: 1, duration: 0.5, innerHTML: "REA" },
              "<"
            );
            gsap.to(
              title_b,
              { opacity: 1, duration: 0.5, innerHTML: "CH" },
              ">"
            );
            break;
          default:
            break;
        }
      });
      side.addEventListener("mouseleave", function handleTitleChange(event) {
        switch (currentSelection) {
          case "S":
            gsap.to(title_a, { opacity: 0, duration: 0.5 });
            gsap.to(title_b, { opacity: 0, duration: 0.5 }, ">");
            gsap.to(
              title_a,
              { opacity: 1, duration: 0.5, innerHTML: "IN" },
              "<"
            );
            gsap.to(
              title_b,
              { opacity: 1, duration: 0.5, innerHTML: "TRO" },
              ">"
            );

            break;
          case "A":
            gsap.to(title_a, { opacity: 0, duration: 0.5 });
            gsap.to(title_b, { opacity: 0, duration: 0.5 }, ">");
            gsap.to(
              title_a,
              { opacity: 1, duration: 0.5, innerHTML: "THRES" },
              "<"
            );
            gsap.to(
              title_b,
              { opacity: 1, duration: 0.5, innerHTML: "HOLD" },
              ">"
            );
            ThresholdClick.current.play();
            break;

          case "B":
            gsap.to(title_a, { opacity: 0, duration: 0.5 });
            gsap.to(title_b, { opacity: 0, duration: 0.5 }, ">");
            gsap.to(
              title_a,
              { opacity: 1, duration: 0.5, innerHTML: "GENE" },
              "<"
            );
            gsap.to(
              title_b,
              { opacity: 1, duration: 0.5, innerHTML: "SIS" },
              ">"
            );
            GenesisClick.current.play();
            break;

          case "C":
            gsap.to(title_a, { opacity: 0, duration: 0.5 });
            gsap.to(title_b, { opacity: 0, duration: 0.5 }, ">");
            gsap.to(
              title_a,
              { opacity: 1, duration: 0.5, innerHTML: "HORI" },
              "<"
            );
            gsap.to(
              title_b,
              { opacity: 1, duration: 0.5, innerHTML: "ZON" },
              ">"
            );
            HorizonClick.current.play();
            break;

          case "D":
            gsap.to(title_a, { opacity: 0, duration: 0.5 });
            gsap.to(title_b, { opacity: 0, duration: 0.5 }, ">");
            gsap.to(
              title_a,
              { opacity: 1, duration: 0.5, innerHTML: "SPA" },
              "<"
            );
            gsap.to(
              title_b,
              { opacity: 1, duration: 0.5, innerHTML: "CE" },
              ">"
            );
            SpaceClick.current.play();
            break;

          case "E":
            gsap.to(title_a, { opacity: 0, duration: 0.5 });
            gsap.to(title_b, { opacity: 0, duration: 0.5 }, ">");
            gsap.to(
              title_a,
              { opacity: 1, duration: 0.5, innerHTML: "IN" },
              "<"
            );
            gsap.to(
              title_b,
              { opacity: 1, duration: 0.5, innerHTML: "SIGHT" },
              ">"
            );
            InsightClick.current.play();
            break;

          case "F":
            gsap.to(title_a, { opacity: 0, duration: 0.5 });
            gsap.to(title_b, { opacity: 0, duration: 0.5 }, ">");
            gsap.to(
              title_a,
              { opacity: 1, duration: 0.5, innerHTML: "HAR" },
              "<"
            );
            gsap.to(
              title_b,
              { opacity: 1, duration: 0.5, innerHTML: "MONY" },
              ">"
            );
            HarmonyClick.current.play();
            break;
          case "G":
            gsap.to(title_a, { opacity: 0, duration: 0.5 });
            gsap.to(title_b, { opacity: 0, duration: 0.5 }, ">");
            gsap.to(
              title_a,
              { opacity: 1, duration: 0.5, innerHTML: "REA" },
              "<"
            );
            gsap.to(
              title_b,
              { opacity: 1, duration: 0.5, innerHTML: "CH" },
              ">"
            );
            break;
          default:
            break;
        }
      });
      side.addEventListener("click", function handleNavClick(event) {
        if (currentSelection === "S") {
          // do nothing
        } else {
          document
            .getElementById("button-" + currentSelection)
            .classList.remove("cube-visible-selected");
        }
        switch (event.target.id) {
          case "button-A":
            setCurrentSelection("A");
            props.setPage("A");
            document
              .getElementById("button-A")
              .classList.add("cube-visible-selected");
            break;
          case "button-B":
            setCurrentSelection("B");
            props.setPage("B");
            document
              .getElementById("button-B")
              .classList.add("cube-visible-selected");
            break;
          case "button-C":
            setCurrentSelection("C");
            props.setPage("C");
            document
              .getElementById("button-C")
              .classList.add("cube-visible-selected");
            break;
          case "button-D":
            setCurrentSelection("D");
            props.setPage("D");
            document
              .getElementById("button-D")
              .classList.add("cube-visible-selected");
            break;
          case "button-E":
            setCurrentSelection("E");
            props.setPage("E");
            document
              .getElementById("button-E")
              .classList.add("cube-visible-selected");
            break;
          case "button-F":
            setCurrentSelection("F");
            props.setPage("F");
            document
              .getElementById("button-F")
              .classList.add("cube-visible-selected");
            break;
          case "button-G":
            setCurrentSelection("G");
            props.setPage("G");
            document
              .getElementById("button-G")
              .classList.add("cube-visible-selected");
            break;
          default:
            break;
        }
      });
    });
    scroll_back.addEventListener("click", () => {
      console.log("scroll to top");
      document.querySelector(".html-root").scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    });
  }, [currentSelection, props]);
  return (
    <div className="html-root">
      <div className="logo-main">
        <div className="s logo-letter"></div>
        <div className="e logo-letter"></div>
        <div className="e logo-letter"></div>
        <div className="d logo-letter"></div>
      </div>
      <div className="left">
        <div className="display-board-left"></div>
        {props.tagline && <div className="tagline"></div>}
        <div className="scroll-back">
          <span className="material-icons" id="button-A">
            arrow_upward
          </span>
        </div>
      </div>
      <div className="center">
        <div className="headline">
          <span className="headline-LHS"></span>
          <span className="headline-RHS"></span>
        </div>
      </div>
      <div className="right">
        <div className="display-board-right"></div>
        {props.navigation && (
          <div className="navigation">
            <div className="cube-grid-container">
              <div className="cube-grid-item"></div>
              <div
                className="cube-grid-item cube-visible cube-initial"
                id="button-A"
              >
                <span className="material-icons" id="button-A">
                  local_fire_department
                </span>
              </div>
              <div className="cube-grid-item"></div>
              <div className="cube-grid-item"></div>
              <div
                className="cube-grid-item cube-visible cube-initial"
                id="button-B"
              >
                <span className="material-icons" id="button-B">
                  compost
                </span>
              </div>
              <div
                className="cube-grid-item cube-visible cube-initial"
                id="button-C"
              >
                <span className="material-icons" id="button-C">
                  air
                </span>
              </div>
              <div
                className="cube-grid-item cube-visible cube-initial"
                id="button-D"
              >
                <span className="material-icons" id="button-D">
                  hive
                </span>
              </div>
              <div
                className="cube-grid-item cube-visible cube-initial"
                id="button-E"
              >
                <span className="material-icons" id="button-E">
                  self_improvement
                </span>
              </div>
              <div className="cube-grid-item" key="9"></div>
              <div
                className="cube-grid-item cube-visible cube-initial"
                id="button-F"
              >
                <span className="material-icons" id="button-F">
                  all_inclusive
                </span>
              </div>
              <div className="cube-grid-item nav-title-a">IN</div>
              <div className="cube-grid-item nav-title-b">TRO</div>
            </div>
            <div className="scroll-down">SCROLL DOWN</div>
            <div className="button-more cube-visible" id="button-G">
              <span className="material-icons" id="button-G">
                category
              </span>
            </div>
          </div>
        )}
        <div className="copyright">© 2024 SEED. All Rights Reserved.</div>
      </div>
      <div className="for-scroll-options">
        <div className="section" id="pos1"></div>
        <div className="section" id="pos2"></div>
        <div className="section" id="pos3"></div>
        <div className="section" id="pos4"></div>
      </div>
      <div className="initiator">
        <div className="image-gif"></div>
        <div className="initiator-quote">
          <span className="initiator-quote-line">
            Harnessing Nature's Wisdom,
          </span>
          <span className="initiator-quote-line">
            Breathing life into the Future
          </span>
          <span className="initiator-quote-line">
            From Deep Sustainable Roots.
          </span>
        </div>
        <div className="initiator-button">ELEVATE</div>
      </div>
    </div>
  );
}
